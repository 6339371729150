import { FieldValidator, getIn } from "final-form";
import isEmail from "validator/es/lib/isEmail";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import isFinite from "lodash/isFinite";

export const required = (value: any) =>
    value === null || value === undefined || value === "" ? "This field is required" : undefined;

export const phoneRequired = (value: any) => {
    return value === null || value === undefined || value?.phoneNumber === "" ? "This field is required" : undefined;
};

export const minValue = (min: number) => (value: number) =>
    Number.isNaN(value) || value >= min ? undefined : `Please enter a value greater than or equal to ${min}`;

export const maxValue = (max: number) => (value: number) =>
    Number.isNaN(value) || value <= max ? undefined : `Please enter a value less than or equal to ${max}`;

export const minLength = (min: number, msg?: string) => (value: string | Array<any>) =>
    !value || value.length >= min ? undefined : msg || `Must be at least ${min} characters long`;

export const maxLength = (max: number, msg?: string) => (value: string | Array<any>) =>
    !value || value.length <= max ? undefined : msg || `Must not be longer than ${max} characters`;

export const email = (value: string) => (!value || isEmail(value) ? undefined : "Please enter a valid email address");
export const phone = (value: string) =>
    !value || window.intlTelInputUtils?.isValidNumber(value) ? undefined : "Please enter a valid phone number";

export const postal = (value: string) =>
    !value || /^\w{2,4} ?\w{2,4}$/.test(value) ? undefined : "Enter a valid postal code";

export const tryParseDate = (value: string | undefined) => {
    if (!value) {
        return undefined;
    }
    const valueParsed = parseISO(value);
    if (!isValid(valueParsed)) {
        return undefined;
    }
    return valueParsed;
};
export const date = (value: string) => {
    return !value || isValid(parseISO(value)) ? undefined : "Please enter a date in DD/MM/YYYY format";
};
export const dateBefore = (field: string) => (value: string, allValues: object) => {
    const valueBefore = tryParseDate(value);
    if (!valueBefore) {
        return undefined;
    }
    const valueAfter = tryParseDate(getIn(allValues, field));
    if (!valueAfter) {
        return undefined;
    }
    return valueBefore.valueOf() < valueAfter.valueOf() ? undefined : `Should be before ${field}`;
};
export const dateAfter = (field: string) => (value: string, allValues: object) => {
    const valueAfter = tryParseDate(value);
    if (!valueAfter) {
        return undefined;
    }
    const valueBefore = tryParseDate(getIn(allValues, field));
    if (!valueBefore) {
        return undefined;
    }
    return valueBefore.valueOf() < valueAfter.valueOf() ? undefined : `Should be after ${field}`;
};
export const pastDate = (value: string): string | undefined => {
    const dateError = date(value);
    if (dateError) {
        return dateError;
    }

    const parsedDate = new Date(value);
    if (parsedDate.valueOf() >= Date.now()) {
        return "Date must be in the past";
    }

    return undefined;
};
export const futureDate = (value: string): string | undefined => {
    const dateError = date(value);
    if (dateError) {
        return dateError;
    }

    const parsedDate = new Date(value);
    if (parsedDate.valueOf() <= Date.now()) {
        return "Please enter a future date";
    }

    return undefined;
};

export const sameAs = (field: string, message?: string) => (value: string, allValues: object) => {
    const otherValue = getIn(allValues, field);
    const realMessage = message || `Should be same as ${field}`;
    return value === otherValue ? undefined : realMessage;
};

export const lessThan = (field: string, message?: string) => (value: number, allValues: object) => {
    const otherValue = getIn(allValues, field);
    if (!isFinite(value) || !isFinite(otherValue)) {
        return undefined;
    }
    const realMessage = message || `Should be less than ${field}`;
    return value < otherValue ? undefined : realMessage;
};

const isLower = (c: string) => c >= "a" && c <= "z";
const isUpper = (c: string) => c >= "A" && c <= "Z";
const isDigit = (c: string) => c >= "0" && c <= "9";

export const hasUpper = (field: string): FieldValidator<string> => (value) =>
    Array.from(value).some((m) => isUpper(m)) ? undefined : `${field} must contain at least one upper case character`;

export const hasLower = (field: string): FieldValidator<string> => (value) =>
    Array.from(value).some((m) => isLower(m)) ? undefined : `${field} must contain at least one lower case character`;

export const hasDigit = (field: string): FieldValidator<string> => (value) =>
    Array.from(value).some((m) => isDigit(m)) ? undefined : `${field} must contain at least one digit`;

export const hasLetter: FieldValidator<string> = (value) =>
    Array.from(value).some((m) => isLower(m) || isUpper(m)) ? undefined : `The field must contain at least one letter`;

export const onlyAlphaNumeric = (msg?: string) => (value: string) =>
    /^[a-z\d]+$/i.test(value) ? undefined : msg || "The field should contains alpha-numeric symbols only";

export const regex = (pattern: RegExp, msg: string) => (value: string) => (pattern.test(value) ? undefined : msg);

export const composeValidators = (...validators: (FieldValidator<any> | false)[]) => (
    value: any,
    allValues: any,
    meta: any
) =>
    validators.filter(Boolean).reduce((error, validator: any) => error || validator(value, allValues, meta), undefined);
