/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, makeStyles, Theme } from "@material-ui/core";
import { Link as NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOff } from "actions/auth";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        padding: "10px 15px",
        cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.navLinkHover} !important`,
            backgroundColor: theme.palette.navLinkHoverBg,
        },
    },
    container: {
        paddingRight: "15px",
        paddingTop: "3px",
        display: "flex",
        alignItems: "start",
    },
}));

interface IProps {
    username: string;
}

const UserInfo: React.FC<IProps> = ({ username }) => {
    const dispatch = useDispatch();
    const styles = useStyles();

    const onLogoff = () => {
        dispatch(logOff());
    };

    return (
        <div className={styles.container}>
            <NavLink className={styles.root} to="/account">
                {username}
            </NavLink>
            <Link className={styles.root} onClick={onLogoff}>
                Log off
            </Link>
        </div>
    );
};

export default UserInfo;
