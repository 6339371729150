import { IPermission } from "models/auth/IdentityData";
import { ActionFunction0, BaseAction, createAction } from "redux-actions";
import * as actions from "./constants/auth";

export interface ISetPermissions {
    permissions?: IPermission[];
    hasAnySitesWithRecurringPayments?: boolean;
}

export const setToken = createAction<string | null>(actions.SET_TOKEN);
export const setPermissions = createAction<ISetPermissions>(actions.SET_PERMISSIONS);
export const loadPermissions: ActionFunction0<BaseAction> = createAction(actions.LOAD_PERMISSIONS);
export const reset: ActionFunction0<BaseAction> = createAction(actions.RESET);

export const logOff = createAction(actions.LOGOUT);
