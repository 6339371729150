import { ISiteDataSimple } from "./ISite";
import { TransactionStatus } from "./ITransaction";

export type PaymentArrangementStatus = "running" | "paused" | "cancelled" | "settled" | "requires_attention";

export default interface IPaymentArrangement {
    id: number;
    correlation: string;
    status: PaymentArrangementStatus;
    paymentDate?: string;
    mainPaymentTotal?: number;
    customerName: string;
    site: ISiteDataSimple;
    paymentAmount: number;
    mainPaymentAmount: number;
    setupPayment: number;
    setupPaymentStatus?: TransactionStatus;
    mainPaymentStatus?: TransactionStatus;
    primaryReference: string;
}

export interface IPaymentArrangementViewableSite {
    id: string; // This will be a GUID
    internalSiteName: string;
}
