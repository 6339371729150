export const getToken = () => {
    return window.localStorage.getItem("token");
};

export const setToken = (token: string | null) => {
    const storage = window.localStorage;
    if (token === null) {
        storage.removeItem("token");
    } else {
        storage.setItem("token", token);
    }
};
