import { ISiteDataSimple } from "./ISite";

export enum TransactionStatus {
    Pending = 1,
    Successful = 2,
    Failed = 3,
}

export default interface ITransaction {
    id: number;
    site: ISiteDataSimple;
    preTransaction: IPreTransaction;
    user: IUser;
    correlation: string;
    authorizationCode?: string;
    paymentNet: number;
    paymentTotal: number;
    refundTotal: number;
    status: TransactionStatus;
    statusStr: string;
    createdDate: string;
}

export interface IPreTransaction {
    payeeName: string;
    payeeAddress: string;
    payeePostcode: string;
    payeePhone: string;
    payeeEmail: string;
    payeeCustom1: string;
    payeeCustom2: string;
    payeeCustom3: string;
    payeeDateOfBirth: string;
    notes: string;
    primaryReference: string;
}

export interface IUser {
    id: string;
    fullName: string;
    email: string;
}

export interface ITransactionViewableSite {
    id: string; // This will be a GUID
    internalSiteName: string;
}
