import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuth } from "reducers/auth";
import { IPermissions } from "models/auth/IdentityData";

interface IProps {
    permission?: keyof IPermissions | (keyof IPermissions)[];
}

const hasAccess = (permissions: IPermissions, ...permission: (keyof IPermissions)[]) => {
    return permission.every((p) => permissions[p]);
};

const PrivateRoute: React.FC<IProps & Omit<RouteProps, "render">> = ({ children, permission, component, ...rest }) => {
    const auth = useSelector(getAuth);
    return (
        <Route
            {...rest}
            render={({ location }) => {
                let authorized = auth.isValid && !!auth.permissions;

                if (
                    authorized &&
                    permission &&
                    !hasAccess(auth.permissions!, ...(Array.isArray(permission) ? permission : [permission]))
                ) {
                    authorized = false;
                }

                const Component: any = component;
                return authorized ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

export default PrivateRoute;
