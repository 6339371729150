import * as React from "react";
import { useEffect } from "react";
import { Button, Container, makeStyles, Theme, Divider, Typography } from "@material-ui/core";
import { TextField } from "mui-rff";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Loader from "components/loader";
import Paper from "components/paper";
import { getAuth } from "reducers/auth";
import { composeValidators, email, required } from "utils/validation";
import AsyncForm from "components/async-form";
import { SET_PERMISSIONS } from "actions/constants/auth";
import { LOGIN, LOGIN_FAILED } from "./Login.constants";

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            paddingBottom: theme.spacing(1),
        },
        "& > button": {
            width: "fit-content",
        },
    },
    errorText: {
        color: theme.palette.error.main,
        alignSelf: "center",
    },
}));

const Login = () => {
    const history = useHistory();
    const { isValid, defaultPage } = useSelector(getAuth);
    const styles = useStyles();

    useEffect(() => {
        if (isValid && defaultPage) {
            history.push(defaultPage);
        }
    }, [isValid, defaultPage, history]);

    return (
        <>
            <Helmet>
                <title>Log in</title>
            </Helmet>
            <Paper padding="15px 15px 5px 15px">
                <Typography variant="h2">Log In</Typography>
                <Typography variant="h4">Use a local account to log in.</Typography>
            </Paper>
            <Divider />
            <Paper position="relative">
                <Container maxWidth="sm">
                    <AsyncForm start={LOGIN} resolve={LOGIN_FAILED} reject={SET_PERMISSIONS}>
                        {({ handleSubmit, submitError }) => (
                            <form onSubmit={handleSubmit} className={styles.form}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    fieldProps={{ validate: composeValidators(required, email) }}
                                />
                                <TextField
                                    label="Password"
                                    name="password"
                                    type="password"
                                    fieldProps={{ validate: required }}
                                />
                                {submitError && <span className={styles.errorText}>{submitError}</span>}
                                <div>
                                    <Button variant="contained" color="primary" type="submit">
                                        Login
                                    </Button>
                                    &nbsp;
                                    <Link to="/account/forgot-password">Forgot Password?</Link>
                                </div>
                            </form>
                        )}
                    </AsyncForm>
                </Container>
                <Loader />
            </Paper>
        </>
    );
};

export default Login;
