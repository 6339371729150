import * as React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import axios from "axios";

import { trimEndSlash } from "utils/stringHelpers";
import Logger from "utils/log/Logger";
import ClientLogger from "utils/log/ClientLogger";
import history from "utils/history";
import { getToken } from "utils/localStorage";
import getTheme from "styles/theme";
import { IThemeSettings } from "styles/IThemeSettings";
import createStore from "store";
import IAppInfo from "models/IAppInfo";
import { IOrganisation } from "models/IOrganisation";
import { info, auth, org, IAuthState } from "reducers/agentReducers";
import App, { loginSaga } from "pages/manage";

Logger.initLogger(new ClientLogger());
const organisation = (window as any).org as IOrganisation;
const themeSettings = (window as any).theme as IThemeSettings;
const appInfo = (window as any).appInfo as IAppInfo;

const store = createStore(
    {
        info: { reducer: info, init: appInfo },
        auth: { reducer: auth },
        org: { reducer: org, init: organisation },
    },
    [loginSaga]
);
const theme = getTheme(themeSettings);

axios.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// We set the base path so that nested sites correctly call the API methods.
// React routing base path is handled within the history.
axios.defaults.baseURL = trimEndSlash(organisation.urlPathPrefix);

declare module "state" {
    interface IAppState {
        info: IAppInfo;
        auth?: IAuthState;
        org: IOrganisation;
    }
}

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router history={history}>
                <CssBaseline>
                    <App />
                </CssBaseline>
            </Router>
        </Provider>
    </ThemeProvider>,
    document.getElementById("root")
);
