import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import Paper from "components/paper";
import { IPermissions } from "models/auth/IdentityData";

const useStyles = makeStyles((theme: Theme) => ({
    navLink: {
        textDecoration: "none",
        padding: "10px",
        marginLeft: "1px",
        "&:hover": {
            color: theme.palette.navLinkHover,
            backgroundColor: theme.palette.navLinkHoverBg,
        },
    },
    navLinkActive: {
        color: "#fff !important",
        backgroundColor: "#337ab7",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#337ab7",
        },
    },
    navPanel: {
        padding: "2px",
        display: "flex",
        flexWrap: "wrap",
    },
}));

interface IProps {
    to: string;
    styles: Record<"navLink" | "navLinkActive", string>;
    hidden: boolean;
}

const PermissionNavLink: React.FC<IProps> = ({ to, styles, hidden, children }) => {
    return hidden ? null : (
        <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} exact to={to}>
            {children}
        </NavLink>
    );
};

interface INavMenuProps {
    permissions: Partial<IPermissions>;
}

const NavMenu: React.FC<INavMenuProps> = ({ permissions }) => {
    const styles = useStyles();
    return (
        <Paper className={styles.navPanel}>
            <PermissionNavLink hidden={!permissions.canTakePhonePayments} styles={styles} to="/phone-payment">
                Phone payment
            </PermissionNavLink>
            <PermissionNavLink hidden={!permissions.canViewTransactions} styles={styles} to="/">
                Transactions
            </PermissionNavLink>
            <PermissionNavLink hidden={!permissions.canViewPaymentArrangements} styles={styles} to="/future-payments">
                Future Payments
            </PermissionNavLink>
            <PermissionNavLink hidden={!permissions.canManageAnyUsers} styles={styles} to="/users">
                User accounts
            </PermissionNavLink>
            <PermissionNavLink hidden={!permissions.canManageAnySites} styles={styles} to="/sites">
                Payment sites
            </PermissionNavLink>
            <PermissionNavLink hidden={!permissions.canRunReports} styles={styles} to="/reports">
                Reports
            </PermissionNavLink>
            <PermissionNavLink
                hidden={!permissions.canReadPermissionsConfig || !permissions.canManageAnyUsers}
                styles={styles}
                to="/security"
            >
                Permissions
            </PermissionNavLink>
            <PermissionNavLink hidden={!permissions.canViewAuditLogs} styles={styles} to="/audit-logs">
                Audit logs
            </PermissionNavLink>
        </Paper>
    );
};

export default React.memo(NavMenu);
