import { all, put, call, takeEvery, select } from "redux-saga/effects";
import { Action } from "redux-actions";
import axios, { AxiosResponse } from "axios";
import { FORM_ERROR } from "final-form";

import { IAppState } from "state";
import { IItemsList } from "models/IPaginatableResponse";
import { IPaymentArrangementViewableSite } from "models/IPaymentArrangement";
import { IPermission } from "models/auth/IdentityData";
import { LOAD_PERMISSIONS, LOGOUT } from "actions/constants/auth";
import { setToken, setPermissions } from "actions/auth";
import { setLoading } from "components/loader/Loader.actions";
import history from "utils/history";
import { setError } from "utils/saga";
import { hasPermissionForAnySubtenant } from "utils/permissions";
import { setToken as setLocalStorageToken } from "utils/localStorage";
import { LOGIN } from "./Login.constants";
import { IAuthentication } from "./Login.models";
import { logInFailed } from "./Login.actions";

interface ILoginResponse {
    needReset: boolean;
    token: string;
    expiration: string;
}

function* LoadPermissions() {
    const token = yield select((state: IAppState) => state.auth?.token);
    if (!token) {
        history.push("/login");
        return;
    }

    try {
        const { data }: AxiosResponse<IPermission[]> = yield call(() =>
            axios.get("/Permissions/CurrentUser/allowedPermissionNames")
        );

        let hasAnySitesWithRecurringPayments = false;
        if (hasPermissionForAnySubtenant("ViewAllTransactions", data)) {
            const {
                data: viewArrangementsSites,
            }: AxiosResponse<IItemsList<IPaymentArrangementViewableSite>> = yield call(() =>
                axios.get("/api/paymentArrangement/sites")
            );
            hasAnySitesWithRecurringPayments = !!viewArrangementsSites.items.length;
        }

        yield put(setPermissions({ permissions: data, hasAnySitesWithRecurringPayments }));
    } catch (e) {
        yield call(setError, e);
    }
}

function* LoginRequest({ payload: body }: Action<IAuthentication>) {
    yield put(setLoading(true));
    try {
        const {
            data: { needReset, token },
        }: AxiosResponse<ILoginResponse> = yield call(() => axios.post("/api/account/login", body));

        if (needReset) {
            yield call(() =>
                history.push(`/account/reset-password?token=${encodeURIComponent(token)}&email=${body.email}`)
            );
            return;
        }

        yield put(setToken(token));
        yield call(() => setLocalStorageToken(token));
        yield call(LoadPermissions);
    } catch (e) {
        yield put(
            logInFailed({ [FORM_ERROR]: "Incorrect user name or password or user does not exist or have access" })
        );
    } finally {
        yield put(setLoading(false));
    }
}

function* LogOff() {
    yield put(setToken(null));
    yield put(setPermissions({ permissions: undefined, hasAnySitesWithRecurringPayments: undefined }));
    setLocalStorageToken(null);
    history.push("/login");
}

export default function* () {
    yield all([
        takeEvery(LOGIN, LoginRequest),
        takeEvery(LOAD_PERMISSIONS, LoadPermissions),
        takeEvery(LOGOUT, LogOff),
    ]);
}
