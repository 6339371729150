import { put } from "redux-saga/effects";
import { setError as error } from "actions/error";
import { AxiosError } from "axios";
import { logOff } from "actions/auth";
import Logger from "./log/Logger";
import history from "./history";

const isAxiosError = (e: any): e is AxiosError => !!e.response;

export function* setError(e: any) {
    Logger.error(e);
    if (isAxiosError(e) && e.response!.status !== 500) {
        if (e.response!.status === 404) {
            history.push("/not-found");
            return;
        }
        if (e.response!.status === 401) {
            yield put(logOff());
            return;
        }
        if (e.response!.status === 403) {
            history.replace("/forbidden");
            return;
        }
    }

    yield put(error(e));
    history.push("/error");
}
