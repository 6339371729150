import { IPermission, KnownPermissionNames } from "models/auth/IdentityData";

export const hasPermissionForAnySubtenant = (name: KnownPermissionNames, permissions: IPermission[]) =>
    !!permissions.find((m) => m.permissionName === name && m.allowedSubTenants && m.allowedSubTenants.length);

export const hasPermissionForOrg = (name: KnownPermissionNames, permissions: IPermission[]) =>
    !!permissions.find((m) => m.permissionName === name && m.allowedSubTenants === null);

export const subTenantsForPermission = (name: KnownPermissionNames, permissions: IPermission[]) =>
    permissions.find((m) => m.permissionName === name)?.allowedSubTenants;
