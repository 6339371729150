import * as React from "react";
import { useEffect } from "react";
import { Container, Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { loadPermissions } from "actions/auth";
import { getAuth } from "reducers/auth";
import { getOrg } from "reducers/organisation";
import Header from "components/header";
import { BoxedLoader } from "components/loader";
import Footer from "./components/footer";
import UserInfo from "./components/user-info";
import NavMenu from "./components/nav-menu";

interface IProps {
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    allowAnonymous?: boolean;
}

const AdminAppContainer: React.FC<IProps> = ({ children, maxWidth, allowAnonymous }) => {
    const { username, permissions } = useSelector(getAuth);
    const { name } = useSelector(getOrg);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!allowAnonymous && !permissions) {
            dispatch(loadPermissions());
        }
    }, [dispatch, allowAnonymous, permissions]);

    let content = null;
    if (allowAnonymous) {
        content = children;
    } else {
        content = permissions ? (
            <>
                <NavMenu permissions={permissions} />
                <Divider />
                {children}
            </>
        ) : (
            <BoxedLoader />
        );
    }

    return (
        <Container maxWidth={maxWidth}>
            <Helmet titleTemplate={`%s - ${name}`} defaultTitle={name} />
            <Header>{username && <UserInfo username={username} />}</Header>
            <Divider />
            {content}
            <Divider />
            <Footer brand={name} />
        </Container>
    );
};

export default AdminAppContainer;
