/* eslint-disable react/no-danger */
import * as React from "react";
import Paper from "components/paper";

interface IProps {
    brand: string;
    footerHtml?: string;
}
const Footer: React.FC<IProps> = ({ brand, footerHtml }) => {
    const year = new Date().getFullYear().toString();
    const footer = footerHtml ? (
        <div dangerouslySetInnerHTML={{ __html: footerHtml.replace("%year%", year) }} />
    ) : (
        <div>
            {year} &copy; {brand}
        </div>
    );
    return <Paper>{footer}</Paper>;
};

export default Footer;
