import * as React from "react";
import { Form, FormProps } from "react-final-form";
import MakeAsyncFunction from "react-redux-promise-listener";
import { promiseListener } from "store";

interface IProps {
    start: string;
    resolve: string;
    reject: string;
}

function AsyncForm<FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>({
    start,
    resolve,
    reject,
    ...rest
}: Omit<FormProps<FormValues, InitialFormValues>, "onSubmit"> & IProps) {
    return (
        <MakeAsyncFunction listener={promiseListener} start={start} resolve={resolve} reject={reject}>
            {(asyncFunc: any) => <Form onSubmit={asyncFunc} {...rest} />}
        </MakeAsyncFunction>
    );
}

export default AsyncForm;
