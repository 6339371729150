import { Action, handleActions } from "redux-actions";
import { IPermission, IPermissions, IToken } from "models/auth/IdentityData";
import { IAppState } from "state";
import { getToken } from "utils/localStorage";
import { hasPermissionForAnySubtenant, hasPermissionForOrg, subTenantsForPermission } from "utils/permissions";
import parseJwt from "jwt-decode";
import { createSelector } from "reselect";
import { SET_TOKEN, SET_PERMISSIONS, RESET } from "actions/constants/auth";
import { ISetPermissions } from "actions/auth";

export interface IAuthState {
    token?: IToken;
    permissions?: IPermission[];
    hasAnySitesWithRecurringPayments?: boolean;
}

const getDefaultState = (): IAuthState | null => {
    const tokenStr = getToken();
    if (!tokenStr) {
        return null;
    }

    return { token: parseJwt<IToken>(tokenStr) };
};

export const auth = handleActions<IAuthState | null, any>(
    {
        [SET_TOKEN]: (state, { payload: token }: Action<string | null>) => {
            return { ...state!, token: token ? parseJwt<IToken>(token) : undefined };
        },
        [SET_PERMISSIONS]: (state, { payload }: Action<ISetPermissions | undefined>) => ({
            ...state!,
            ...payload,
        }),
        [RESET]: () => getDefaultState(),
    },
    getDefaultState()
);

export interface IAuthView {
    isValid: boolean;
    id?: string;
    username?: string;
    permissions?: IPermissions;
    defaultPage?: string;
}

const pageForPermission: ((permissions: IPermissions) => string | false)[] = [
    (permissions) => permissions.canViewTransactions && "/",
    (permissions) => permissions.canReadPermissionsConfig && permissions.canManageAnyUsers && "/security",
    (permissions) => permissions.canTakePhonePayments && "/phone-payment",
    (permissions) => permissions.canManageAnyUsers && "/users",
    (permissions) => permissions.canManageAnySites && "/sites",
    (permissions) => permissions.canRunReports && "/reports",
    (permissions) => permissions.canViewAuditLogs && "/audit-logs",
];

export const getAuth = createSelector(
    (state: IAppState) => state.auth?.token,
    (state) => state.auth?.permissions,
    (state) => state.auth?.hasAnySitesWithRecurringPayments,
    (token, permissions, hasAnySitesWithRecurringPayments): IAuthView => {
        if (!token) {
            return { isValid: false };
        }

        let permissionsView: IPermissions | undefined;
        let defaultPage: string | undefined;
        if (permissions) {
            permissionsView = {
                canTakePhonePayments: hasPermissionForAnySubtenant("TakePhonePayment", permissions),
                canManageAnyUsers: hasPermissionForOrg("ManageUsers", permissions),
                canManageAnySites: hasPermissionForAnySubtenant("ManageSite", permissions),
                canViewAuditLogs: hasPermissionForAnySubtenant("ViewAuditLogs", permissions),
                canViewPaymentArrangements:
                    !!hasAnySitesWithRecurringPayments &&
                    hasPermissionForAnySubtenant("ViewAllTransactions", permissions),
                sitesWhereCanOverrideFees: subTenantsForPermission("OverrideFees", permissions),
                canViewTransactions: hasPermissionForAnySubtenant("ViewAllTransactions", permissions),
                sitesWhereCanRefundPayments: subTenantsForPermission("RefundPayments", permissions),
                canRunReports: hasPermissionForAnySubtenant("RunReports", permissions),
                canReadPermissionsConfig: hasPermissionForOrg("Permissions.Config.Read", permissions),
                canCreatePermissionsPatch: hasPermissionForOrg("Permissions.Patches.Create", permissions),
                canApplyPermissionsPatch: hasPermissionForOrg("Permissions.Patches.Apply", permissions),
                canReadPermissionsPatches: hasPermissionForOrg("Permissions.Patches.Read", permissions),
                canRemovePermissionsPatches: hasPermissionForOrg("Permissions.Patches.Delete", permissions),
                sitesWhereCanSendQuickPayLink: subTenantsForPermission("SendQuickPaymentLink", permissions),
            };

            defaultPage = "/account";
            for (let i = 0; i < pageForPermission.length; i += 1) {
                const page = pageForPermission[i](permissionsView!);
                if (page) {
                    defaultPage = page;
                    break;
                }
            }
        }

        return {
            isValid: token.exp * 1000 > Date.now(),
            id: token.sub,
            username: token.email,
            permissions: permissionsView,
            defaultPage,
        };
    }
);
